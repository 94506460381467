import React from "react";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/Helmet";

import { Container, Row, Col } from "reactstrap";
import OurMembers from "../components/UI/OurMembers";
import AboutSection from "../components/UI/AboutSection";
import AssurancesTypesData from "../assets/data/AssurancesTypesData";
import { HotOffersSerction } from "../components/UI/HotOffersSerction";
import AssuranceItem from "../components/UI/AssuranceItem";
import AssuranceItemGrande from "../components/UI/AssuranceItemGrande";
import Testimonial from "../components/UI/Testimonial";
import AccesRapide from "../components/UI/AccesRapide";

import BlogList from "../components/UI/BlogList";

const idsToDisplay = [3, 5]; // IDs des éléments que vous souhaitez afficher
const filteredAssurances = AssurancesTypesData.filter(item => idsToDisplay.includes(item.id));

const Home = () => {
  return (
    <Helmet title="Accueil">
      {/* ============= hero section =========== */}
      <section className="p-0 hero__slider-section">
        <HeroSlider />

        <div className="hero__form">
          <Container>
            <Row>
              <Col lg="12" md="8" sm="12">
                <AccesRapide />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* =========== about section ================ */}
      <AboutSection />
      {/* =========== Partenaires section ========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">

              <h2 className="section__title_grand">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
      {/* ========== services section ============ */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title ">Nos Assurances</h2>
            </Col>
            {AssurancesTypesData.slice(0, 3).map((item) => (
              <AssuranceItem item={item} key={item.id} />
            ))}
            {AssurancesTypesData.slice(4, 7).map((item) => (
              <AssuranceItem item={item} key={item.id} />
            ))}
            {filteredAssurances.map((item) => (
              <AssuranceItemGrande item={item} key={item.id} />
            ))}
          </Row>
        </Container>
      </section>
      {/* =========== testimonial section =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-2 text-center">
              <h2 className="section__title_grand">Nos Clients Témoignent</h2>
              <HotOffersSerction />
            </Col>

            <Testimonial />

          </Row>
        </Container>
      </section>
      {/* =============== News =========== */}
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title_grand">Actualités Assurances</h2>
            </Col>

          </Row>
          <BlogList />
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
