import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/header.css";
import AssurancesTypesData from "../../assets/data/AssurancesTypesData";

const Header = () => {
  const menuRef = useRef(null);
  const assurancesRef = useRef(null);
  const assurancesReftwo = useRef(null);
  const assurancesRefThree = useRef(null);

  const dropdownRef = useRef(null);
  const dropdownRefTwo = useRef(null);
  const dropdownRefThree = useRef(null) ;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownTwoOpen, setIsDropdownTwoOpen] = useState(false);
  const [isDropdownThreeOpen, setIsDropdownThreeOpen] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [dropdownPositionTwo, setDropdownPositionTwo] = useState({ top: 0, left: 0 });
  const [dropdownPositionThree, setDropdownPositionThree] = useState({ top: 0, left: 0 });


  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setIsSubmitted(false);
    setPhoneNumber('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`https://preemback-693343307929.us-central1.run.app/api/send_email/${phoneNumber}`, {
        method: 'GET',
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        console.error('Erreur lors de l\'envoi de l\'email');
      }
    } catch (error) {
      console.error('Erreur de réseau:', error);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleDropdowntwo = () => {
    setIsDropdownTwoOpen(!isDropdownTwoOpen);
  };

  const toggleDropdownThree = () => {
    setIsDropdownThreeOpen(!isDropdownThreeOpen);
  };

  useEffect(() => {
    if (assurancesRef.current) {
      const rect = assurancesRef.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    if (assurancesReftwo.current) {
      const rect = assurancesReftwo.current.getBoundingClientRect();
      setDropdownPositionTwo({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
  }, [isDropdownTwoOpen]);

  useEffect(() => {
    if (assurancesRefThree.current) {
      const rect = assurancesRefThree.current.getBoundingClientRect();
      setDropdownPositionThree({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    }
  }, [isDropdownThreeOpen]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        assurancesRef.current && !assurancesRef.current.contains(event.target) &&
        dropdownRef.current && !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        assurancesReftwo.current && !assurancesReftwo.current.contains(event.target) &&
        dropdownRefTwo.current && !dropdownRefTwo.current.contains(event.target)
      ) {
        setIsDropdownTwoOpen(false);
      }
        if (
          assurancesRefThree.current && !assurancesRefThree.current.contains(event.target) &&
          dropdownRefThree.current && !dropdownRefThree.current.contains(event.target)
        ) {
          setIsDropdownThreeOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <header className="header">
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="12" md="6" sm="6">
              <div className="header__top__left">
                <span>Besoin d'Aide ?</span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i>Agence: 04 65 84 50 29
                </span>
                <span className="header__top__help">
                  <i className="ri-phone-fill"></i>Portable Gestion: 07 57 93 37 97
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="4" md="3" sm="4">
              <div className="logo">
                <h1>
                  <Link to="/home" className=" d-flex align-items-center gap-2">
                    <i className="ri--vip-diamond-fill"></i>
                    <span>
                      Prééminence <br /> Assurances
                    </span>
                  </Link>
                </h1>
                <div className="header__location-content">
                  <h6>Rc professionnelle & garantie financiere</h6>
                  <h6>ORIAS : 23006139</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  
                  <h6>160 ROUTE DE CANNES,</h6>
                  <h6>06130 GRASSE</h6>
                  <h4>France</h4>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i className="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>du lundi au vendredi</h4>
                  <h6>9:00 - 19:00 ( SAMEDI : 10:00 - 13:00 )</h6>
                </div>
              </div>
            </Col>

            <Col lg="2" md="3" sm="0" className=" d-flex align-items-center justify-content-end ">
              <button className="header__btn btn" onClick={openModal}>
                <i className="ri-phone-line"></i>Demander Un Appel
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i className="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                <Link to="/home" className="nav__item" key="Home">Accueil</Link>
                <Link to="/about" className="nav__item" key="About">Qui Sommes Nous ?</Link>
                <div ref={assurancesRef} className="nav__item dropdown" onClick={toggleDropdown}>
                  <span>Assurances Construction</span>
                </div>
                <Link to="/assurances/MS" className="nav__item" key="About">Mutuelle Santé</Link>
                <div ref={assurancesReftwo} className="nav__item dropdown" onClick={toggleDropdowntwo}>
                  <span>Assurances Auto</span>
                </div>
                
                <Link to="/contact" className="nav__item" key="Contact">Contact</Link>



              </div>
            </div>
          </div>
        </Container>
      </div>

      {isDropdownOpen && (
        <div className="dropdown__menu" ref={dropdownRef} style={{ top: dropdownPosition.top, left: dropdownPosition.left }}>
          {AssurancesTypesData.slice(0, 4).map((item, index) => (
            <Link
              to={`/assurances/${item.abrv}`}
              className="dropdown__item"
              key={index}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}

      {isDropdownTwoOpen && (
        <div className="dropdown__menu" ref={dropdownRefTwo} style={{ top: dropdownPositionTwo.top, left: dropdownPositionTwo.left }}>
          {AssurancesTypesData.slice(4, 7).map((item, index) => (
            <Link
              to={`/assurances/${item.abrv}`}
              className="dropdown__item"
              key={index}
            >
              {item.name}
            </Link>
          ))}
        </div>
      )}

      

      {modalIsOpen && (
        <div className="custom-modal">
          <div className="custom-modal-content">
            {!isSubmitted ? (
              <form onSubmit={handleSubmit}>
                <label htmlFor="phoneNumber">Numéro de Téléphone:</label>
                <input
                  type="tel"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
                <button type="submit">Envoyer</button>
              </form>
            ) : (
              <div>
                <p>Merci pour votre demande. Nous vous appellerons bientôt.</p>
                <button onClick={closeModal}>Fermer</button>
              </div>
            )}
          </div>
          <div className="custom-modal-overlay" onClick={closeModal}></div>
        </div>
      )}
    </header>
  );
};

export default Header;
