import React from "react";
import Helmet from "../components/Helmet/Helmet";
import { Container, Col } from "reactstrap";


import "../styles/thank-you.css";
import { Link } from "react-router-dom";

const Thankyou = () => {
    return (
        <Helmet title="Merci">
            <section className="thankyou__section mb-6">
                <Container className="text-center">
                    <h1 className="text-light">Merci, vous serez contacté dans 15 minutes</h1>
                    <section>
                        <Col lg="12" className="text-center mb-3">
                            <div className="rectangle-container-new">
                                <Link to="/home" rel="noopener noreferrer" className="no-outline">
                                    <h3>Retour à l'accueil</h3>
                                </Link>
                            </div>
                        </Col>
                    </section>
                </Container>
            </section>
        </Helmet>
    )
}

export default Thankyou