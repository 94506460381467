import React, { Fragment } from "react";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import WhatsApp from "../WhatssapBouton/WhatsApp";
import Routers from "../../routers/Routers";

const Layout = () => {
  return (
    <Fragment>
      <Header />
      
      <div>
        <Routers />     
      </div>
      <WhatsApp />
      <Footer />
    </Fragment>
  );
};

export default Layout;
