import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";
import img01 from "../assets/all-images/assurences-img/DO.png" ;


import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";


const Do = () => {
  return (
    <Helmet title="Dommages Ouvrages">
      <CommonSection title="Assurance Dommages Ouvrages" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Assurance Dommages Ouvrages
                </h2>

                <h4 className="sous_titre">Remboursement rapide des travaux de réparation</h4>
                <p className="section__description">
                L'assurance dommages ouvrages garantit un remboursement rapide des travaux de réparation, assurant ainsi une reprise rapide de l'activité en cas de sinistre.
                </p>

                <h4 className="sous_titre">Couverture des dommages dès la réception des travaux</h4>
                <p className="section__description">
                Dès la réception des travaux, cette assurance prend en charge les dommages pouvant affecter la construction, offrant une protection immédiate.
                </p>

                <h4 className="sous_titre">Sécurité financière pour le maître d'ouvrage</h4>
                <p className="section__description">
                Elle procure une sécurité financière au maître d'ouvrage, en couvrant les coûts imprévus liés aux réparations, sans attendre les décisions judiciaires.
                </p>

                <h4 className="sous_titre">Simplification des démarches administratives</h4>
                <p className="section__description">
                Les démarches administratives sont simplifiées, permettant de gérer les sinistres plus efficacement et de réduire les délais de traitement.
                </p>

                <h4 className="sous_titre">Protection contre les malfaçons</h4>
                <p className="section__description">
                Cette assurance protège contre les malfaçons, garantissant la qualité des travaux et la conformité aux normes en vigueur.
                </p>

                <h4 className="sous_titre">Assistance en cas de sinistre</h4>
                <p className="section__description">
                En cas de sinistre, une assistance est fournie pour accompagner le maître d'ouvrage dans les démarches nécessaires, facilitant ainsi la gestion des incidents.
                </p>

                <h4 className="sous_titre">Garanties étendues pour tous les types de constructions</h4>
                <p className="section__description">
                Les garanties s'étendent à tous les types de constructions, qu'il s'agisse de maisons individuelles, de bâtiments commerciaux ou de structures industrielles, offrant une couverture complète et adaptée.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >


          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Dommages Ouvrages"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Do;
