import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/find-car-form.css";
import { Form, FormGroup, Input, Button } from "reactstrap";
import AssurancesTypesData from "../../assets/data/AssurancesTypesData";

const AccesRapide = () => {
  const [selectedAssurance, setSelectedAssurance] = useState("");
  const navigate = useNavigate();

  const handleSelectChange = (e) => {
    setSelectedAssurance(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedAssurance) {
      // Naviguer vers /devis avec le nom de l'assurance comme paramètre
      navigate(`/devis?assurance=${selectedAssurance}`);
    }
  };

  return (
    <Form className="form" onSubmit={handleSubmit}>
      <div>
        <h2>Accès Rapide à votre Assurance</h2>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <FormGroup className="select__group">
          <Input type="select" value={selectedAssurance} onChange={handleSelectChange}>
            <option value="">Sélectionnez une assurance</option>
            {AssurancesTypesData.map((assurance) => (
              <option key={assurance.id} value={assurance.name}>
                {assurance.name}
              </option>
            ))}
          </Input>
        </FormGroup>

        <FormGroup className="form__group">
          <Button className="btn find__car-btn" type="submit">Obtenir un devis</Button>
        </FormGroup>
      </div>
    </Form>
  );
};

export default AccesRapide;
