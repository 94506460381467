import React from 'react';

const CarteMaps = () => {
    return (
        <>
       <div style={{ width: '100%', height: '200px' }}>
        <iframe
          title = "map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d46183.34394249387!2d6.928326!3d43.659423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cc25fe3958edfb%3A0x117727988cf82e2d!2sGrasse%2C%20France!5e0!3m2!1sen!2sus!4v1717547454179!5m2!1sen!2sus" 
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
        </>
    );
};

export default CarteMaps;
