import React from "react";
import "./whatsapp.css";

const WhatsApp = () => {
  const handleClick = () => {
    window.open("https://wa.me/+33745890161", "_blank");
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <i className="ri-whatsapp-line"></i>
    </div>
  );
};

export default WhatsApp;
