import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";
import img01 from "../assets/all-images/assurences-img/RCD.png" ;

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";


import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const Rcd = () => {
  return (
    <Helmet title="Responsabilité Civile Décennale">
      <CommonSection title="Assurance Responsabilité Civile Décennale" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title">
                  Assurance Responsabilité Civile Décennale
                </h2>

                <h4 className="sous_titre">Protection contre les vices cachés</h4>
                <p className="section__description">
                L'assurance responsabilité civile décennale offre une protection contre les vices cachés, garantissant ainsi la qualité et la durabilité des travaux réalisés.
                </p>

                <h4 className="sous_titre">Couverture des dommages matériels et immatériels</h4>
                <p className="section__description">
                Elle couvre les dommages matériels et immatériels qui pourraient survenir après la réception des travaux, protégeant ainsi l'entreprise et ses clients contre les imprévus.
                </p>

                <h4 className="sous_titre">Garantie de dix ans après la réception des travaux</h4>
                <p className="section__description">
                Cette assurance offre une garantie de dix ans à partir de la réception des travaux, assurant une tranquillité d'esprit à long terme pour les maîtres d'ouvrage.
                </p>

                <h4 className="sous_titre">Prise en charge des frais de réparation</h4>
                <p className="section__description">
                En cas de sinistre, l'assurance prend en charge les frais de réparation, évitant ainsi des coûts supplémentaires importants pour les professionnels du bâtiment.
                </p>

                <h4 className="sous_titre">Assistance juridique en cas de litige</h4>
                <p className="section__description">
                En cas de litige, une assistance juridique est fournie pour aider à résoudre les conflits rapidement et efficacement, protégeant ainsi les intérêts de l'entreprise.
                </p>

                <h4 className="sous_titre">Sérénité pour les maîtres d'ouvrage</h4>
                <p className="section__description">
                Grâce à cette assurance, les maîtres d'ouvrage bénéficient d'une sérénité accrue, sachant que les travaux sont couverts contre les malfaçons et les défauts de construction.
                </p>

                <h4 className="sous_titre">Conformité aux obligations légales</h4>
                <p className="section__description">
                Enfin, l'assurance responsabilité civile décennale permet de se conformer aux obligations légales, garantissant ainsi une pratique professionnelle respectueuse des normes en vigueur.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}
              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >

          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection  nom="Responsabilité Civile Décennale"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet >
  );
};

export default Rcd;
