import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import img01 from "../assets/all-images/assurences-img/RCP.png" ;


import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const Rcp = () => {
  return (
    <Helmet title="RC professionnelle">
      <CommonSection title="Assurance RC professionnelle" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title">
                  Assurance Responsabilité Civile Professionnelle
                </h2>

                <h4 className="sous_titre">Protection en cas de faute professionnelle</h4>
                <p className="section__description">
                L'assurance responsabilité civile professionnelle protège l'entreprise en cas de faute professionnelle, garantissant ainsi une couverture face aux erreurs ou négligences commises.
                </p>

                <h4 className="sous_titre">Couverture des dommages corporels, matériels et immatériels</h4>
                <p className="section__description">
                Elle couvre les dommages corporels, matériels et immatériels causés à des tiers, assurant une protection complète en cas d'incident.
                </p>

                <h4 className="sous_titre">Assistance juridique en cas de litige</h4>
                <p className="section__description">
                En cas de litige, une assistance juridique est fournie pour défendre les intérêts de l'entreprise, facilitant la gestion des conflits et des réclamations.
                </p>

                <h4 className="sous_titre">Sécurité financière pour l'entreprise</h4>
                <p className="section__description">
                Cette assurance offre une sécurité financière en prenant en charge les coûts imprévus liés aux réclamations et aux litiges, protégeant ainsi les ressources de l'entreprise.
                </p>

                <h4 className="sous_titre">Préservation de la réputation professionnelle</h4>
                <p className="section__description">
                En cas d'incident, l'assurance aide à préserver la réputation professionnelle de l'entreprise, en gérant efficacement les réclamations et en minimisant les impacts négatifs.
                </p>

                <h4 className="sous_titre">Prise en charge des frais de défense</h4>
                <p className="section__description">
                Les frais de défense sont pris en charge, offrant un soutien financier et juridique essentiel pour faire face aux accusations et aux poursuites judiciaires.
                </p>

                <h4 className="sous_titre">Garantie de la continuité de l'activité</h4>
                <p className="section__description">
                Enfin, l'assurance responsabilité civile professionnelle garantit la continuité de l'activité en protégeant l'entreprise contre les interruptions dues aux litiges et aux réclamations.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >

          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Responsabilité Civile Professionnelle"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Rcp;
