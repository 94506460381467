import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";

const socialLinks = [
    {
        url: "#",
        icon: "ri-facebook-line",
    },
    {
        url: "#",
        icon: "ri-instagram-line",
    },
    {
        url: "#",
        icon: "ri-linkedin-line",
    },
    {
        url: "#",
        icon: "ri-twitter-line",
    },
];
/* 
                                <FormGroup className="contact__form">
                                    <Button color="primary" onClick={() => window.open(nomenclaturePDF, '_blank')}>
                                        Télécharger La Table des activités
                                    </Button>
                                </FormGroup>
*/

const MontionsLegales = () => {
    return (
        <Helmet title="Mentions Légales">
            <CommonSection title="Mentions Légales" />
            <section>
                <Container>
                    <Row>
                        <Col lg="7" md="7">
                            <div className="about__page-content">
                                <p className="section__description">
                                    Le site <a target="_blank" href="http://www.preeminence.fr">www.preeminence.fr</a> est la propriété de la société PREEMINENCE ASSURANCES.
                                </p>
                                <h2 className="section__title">
                                    Mentions Légales
                                </h2>


                                <div className="contact__info">
                                    <p className="section__description mb-0">
                                        SAS au capital de 1 000€
                                    </p>

                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">Siège social</h6>
                                        <p className="section__description mb-0">160 route de Cannes 06130 Grasse</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">N° Orias</h6>
                                        <p className="section__description mb-0">23 006 139</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">Téléphone:</h6>
                                        <p className="section__description mb-0">04 65 84 50 29</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">Email :</h6>
                                        <p className="section__description mb-0">contact@preeminence.fr</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">SIRET :</h6>
                                        <p className="section__description mb-0">953 681 780 00011</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">RCS :</h6>
                                        <p className="section__description mb-0">GRASSE 953 681 780</p>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                        <h6 className="fs-6 mb-0">Code NAF :</h6>
                                        <p className="section__description mb-0">6622Z - Activités des agents et courtiers d&#39;assurances</p>
                                    </div>

                                </div>



                                <br></br>

                                <p className="section__description">
                                    <strong>Preeminence Assurances</strong> est titulaire d’une RC Professionnelle ainsi que d&#39;une Garantie
                                    Financière conforme aux obligations légales du Code des Assurances et en particulier à ses
                                    articles L 530-1 et L 530-2.<br></br>
                                    La société est enregistrée auprès Registre unique des Intermédiaires en Assurance, Banque et
                                    Finance (ORIAS) sous le numéro 23 006 130.<br></br>
                                    <br></br>
                                    <h4 className="fs-7 mb-0">Utilisateur :</h4>
                                    Personne physique ou morale qui utilise les pages du site dans la perspective de déposer une
                                    demande de devis, une demande de rappel, ou échanger par chat, ou qui se met en contact par
                                    téléphone ou mail avec les gestionnaires de nos partenaires.
                                    <br></br>
                                    <br></br>
                                    <h4 className="fs-7 mb-0">Internaute :</h4>
                                    Toute personne physique ou morale qui se connecte au site, qu’il soit utilisateur, prestataire
                                    ou autre.
                                    <br></br>
                                    <br></br>
                                    <h4 className="fs-7 mb-0">Partenaire :</h4>  
                                    Entreprise tierce avec laquelle la société entretient des relations commerciales dans le but
                                    d’apporter des services ou des informations complémentaires à l’internaute.
                                    <br></br>
                                    <br></br>
                                    <h4 className="fs-7 mb-0">Déclaration :</h4>
                                    
                                    Le site <a target="_blank" href="http://www.preeminence.fr">www.preeminence.fr</a> et les traitements informatiques de données à caractère personnel
                                    issus de ce site, ont fait l’objet d’une déclaration à la CNIL.<br></br>
                                    <br></br>
                                    <a target="_blank" href="http://www.preeminence.fr">www.preeminence.fr</a> utilise les informations que vous lui transmettez pour enregistrer vos
                                    demandes d’informations, de contact et/ou d’adhésion, de création de compte personnel, et si
                                    vous avez donné votre accord, vous faire part d’informations et d’offres promotionnelles susceptibles de vous intéresser. De même ces informations seront communiquées par
                                    www.preeminence.fr à ses partenaires, dont la liste vous sera transmise sur simple demande
                                    de votre part, afin que les gestionnaires de ses partenaires traitent vos demandes
                                    d’informations et d’étude personnalisées, ou que ses partenaires vous proposent de nouveaux
                                    produits ou offres de services.<br></br>
                                    <br></br>
                                    Les données dont <a target="_blank" href="http://www.preeminence.fr">www.preeminence.fr</a> a besoin pour la finalité pour laquelle elles ont été
                                    collectées, nécessaires au respect d’une obligation légale et/ou à la constatation, à l’exercice
                                    ou à la défense de droits en justice pourront cependant ne pas être supprimées.
                                    <br></br>
                                    <br></br>
                                    Conformément à la loi informatique et liberté en vigueur, vous disposez d’un droit d’accès, et
                                    le cas échéant de rectification des informations à caractère personnelle vous concernant dans
                                    nos fichiers, en vous adressant par écrit à : <stong>PREEMINENCE ASSURANCES</stong>, 160 route de
                                    Cannes 06130 - Grasse. De même, vous pouvez vous opposer à tout moment à l’utilisation
                                    commerciale de vos données par www.preeminence.fr et/ou ses partenaires en vous adressant
                                    à <stong>PREEMINENCE ASSURANCES</stong>à l’adresse susmentionnée.
                                    <br></br>
                                    <br></br>
                                    <h4 className="fs-7 mb-0">Conditions d’accès et d’utilisation du site :</h4>                            
                                    Toute consultation ou utilisation du site implique l’acceptation préalable et sans réserve par
                                    l’utilisateur des présentes conditions d’utilisation et des mentions d’avertissement contenues
                                    dans le présent document à la date de sa consultation. Ainsi, il est vivement recommandé à
                                    l’utilisateur de le lire avec la plus grande attention. L’utilisation du site est entièrement
                                    gratuite pour l’utilisateur. Les prestataires référencés par la société sont les compagnies
                                    d’assurances mentionnées sur le site. La société peut, de plein droit et à tout moment,
                                    modifier la structure et le contenu de son site, ainsi que les services proposés, sans autre
                                    formalité que de porter ces modifications dans les conditions générales d’accès et d’utilisation
                                    du site.
                                </p>

                                <div className=" d-flex align-items-center gap-3 mt-4">
                                    <span className="fs-4">
                                        <i class="ri-phone-line"></i>
                                    </span>

                                    <div>
                                        <h6 className="section__subtitle">Besoin d'aide?</h6>
                                        <h4>07 57 93 37 97</h4>
                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg="5" md="5">
                            <div className="contact__info">
                                <h6 className="fw-bold">Coordonnées</h6>
                                <p className="section__description mb-0">
                                    160 ROUTE DE CANNES,
                                    06130 GRASSE , FRANCE
                                </p>
                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="fs-6 mb-0">Téléphone:</h6>
                                    <p className="section__description mb-0">07 57 93 37 97</p>
                                </div>

                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="mb-0 fs-6">Email:</h6>
                                    <p className="section__description mb-0">contact@preeminence.fr</p>
                                </div>

                                <h6 className="fw-bold mt-4">Follow Us</h6>

                                <div className="d-flex align-items-center gap-4 mt-3">
                                    {socialLinks.map((item, index) => (
                                        <Link to={item.url} key={index} className="social__link-icon">
                                            <i className={item.icon}></i>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet >
    );
};

export default MontionsLegales;
