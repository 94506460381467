import React from "react";
import Slider from "react-slick";
import "../../styles/testimonial.css"; // Assurez-vous que le chemin est correct
import ava01 from "../../assets/all-images/ava-1.jpg";
import ava02 from "../../assets/all-images/ava-2.jpg";
import ava03 from "../../assets/all-images/ava-3.jpg";
import ava04 from "../../assets/all-images/ava-4.jpg";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {testimonialsData.map((item, index) => (
        <div className="testimonial py-4 px-3" key={index}>
          <p className="section__description">{item.description}</p>
          <div className="mt-3 d-flex align-items-center gap-4">
            <div className="testimonial-info">
              <h6 className="mb-0">{item.name}</h6>
              <p className="section__description">{item.role}</p>
              <div className="stars">
                {Array.from({ length: item.rating }).map((_, i) => (
                  <i className="ri-star-fill" key={i}></i>
                ))}
                {Array.from({ length: 5 - item.rating }).map((_, i) => (
                  <i className="ri-star-line" key={i}></i>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

const testimonialsData = [
  {
    description: "Madame Guessous a été gentil mais surtout Très PATIENTE ! Elle a répondu à mes 3547questions avant que je signe mon contrat ! Bravo car perso j’aurais craqué y’a bien longtemps . Je recommande good job 👍",
    imgUrl: ava02,
    name: "Yohann Joubert",
    role: "Client",
    rating: 5,
  },
  {
    description: "Très satisfait opératrice efficace en 24h ma demande était validée",
    imgUrl: ava03,
    name: "PAPAZIAN Brice",
    role: "Client",
    rating: 5,
  },
  {
    description: "Perspicacité .Bonjour,Un seul mot : Perspicace !!! , Merci à Vous !!!",
    imgUrl: ava04,
    name: "Clim.SG",
    role: "Client",
    rating: 5,
  },
  {
    description: "Très bon contact, professionnelle, à l'écoute et disponible. Merci beaucoup",
    imgUrl: ava04,
    name: "Abdellah BAMOU",
    role: "Client",
    rating: 5,
  },
  {
    description: "J'ai fait confiance a prééminence assurance pour me trouver une assurance décennale a bon prix je les recommande car il sont efficaces est très sérieux",
    imgUrl: ava04,
    name: "John Gauthier",
    role: "Client",
    rating: 5,
  },
  {
    description: "Sincèrement très satisfait de l équipe. Interlocutrice très professionnelle et compétente qui a répondu à toutes mes attentes. Je recommande vivement.",
    imgUrl: ava04,
    name: "Layani",
    role: "Client",
    rating: 5,
  },
 /*
  {
    description: "Je ne peux que recommander++ Notre société a bénéficié de l' accompagnement d'une équipe compétente et professionnelle. Mr GUÉRIN a témoigné d'un grand professionnalisme et d'une réelle efficacité. Il s'est montré à notre écoute et a su instaurer un climat de confiance rassurant et agréable.Il a investigué pour pouvoir nous proposer le produit le mieux adapté à notre activité. Notre société dispose des garanties d'assurance nécessaires à son activité génie climatique. Merci pour votre réactivité et votre professionnalisme. Je recommande",
    imgUrl: ava04,
    name: "Challeng'air and co",
    role: "Client",
    rating: 5,
  },
  */
  {
    description: "Interlocuteur réactif. Interlocuteur réactif. Réponse par mail dans les minutes!. Au top enfin des personnes compétentes! Merci",
    imgUrl: ava04,
    name: "Ferreira",
    role: "Client",
    rating: 5,
  },
  {
    description: "Cabinet très réactif. Clarté et précision dans le besoin. Personne agréable. Prix attractif. Merci",
    imgUrl: ava04,
    name: "toufik hammoudi",
    role: "Client",
    rating: 5,
  },

];

export default Testimonial;
