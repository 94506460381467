import React from "react";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import "../../styles/blog-item.css"; // Assurez-vous que le chemin est correct

const BlogList = () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch("https://preemback-693343307929.us-central1.run.app/api/news")
      .then(response => response.json())
      .then(data => setNews(data))
      .catch(error => console.error("Erreur lors de la récupération des actualités:", error));
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {news.map((item, index) => (
        <div className="blog__item py-4 px-3" key={index}>
          <div className="blog__info p-3">
            <a href={item.link} className="blog__title" target="_blank" rel="noreferrer">
              {item.title}
            </a>
            <p className="section__description mt-3">
              {item.description.length > 100
                ? `${item.description.substr(0, 100)}...`
                : item.description}
            </p>

            <a href={item.link} target="_blank" className="read__more" rel="noreferrer">
              Read More
            </a>
            <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">

              <div className="d-flex align-items-center gap-3">
                <span className="d-flex align-items-center gap-1 section__description">
                  Date : {item.date}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default BlogList;
