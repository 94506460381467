import React ,{ useEffect }from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import CarListing from "../pages/CarListing";
import CarDetails from "../pages/CarDetails";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Devis from "../pages/Devis";
import At from "../Assurances/At";
import Do from "../Assurances/Do";
import Mp from "../Assurances/Mp"
import Ms from "../Assurances/Ms";
import Rcd from "../Assurances/Rcd";
import Rcp from "../Assurances/Rcp";
import Atd from "../Assurances/Ats";
import { useLocation } from 'react-router-dom';
import Reclamations from "../pages/Reclamations";
import VtcTaxi from "../Assurances/VtcTaxi";
import Thankyou from "../pages/Thankyou";
import MontionsLegales from "../pages/MontionsLegales";


const Routers = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
  
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/cars" element={<CarListing />} />
      <Route path="/cars/:slug" element={<CarDetails />} />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/devis" element={<Devis />} />
      <Route path="/reclamations" element={<Reclamations />} />
      <Route path="/mentionslegales" element={<MontionsLegales />} />
      {/* Assurances */}
      <Route path="/assurances/at" element={<At />} />
      <Route path="/assurances/atd" element={<Atd />} />
      <Route path="/assurances/do" element={<Do />} />
      <Route path="/assurances/mp" element={<Mp />} />
      <Route path="/assurances/ms" element={<Ms />} />
      <Route path="/assurances/rcd" element={<Rcd />} />
      <Route path="/assurances/rcp" element={<Rcp />} />
      <Route path="/assurances/vtc" element={<VtcTaxi />} />
      

      <Route path="/thankyou" element={<Thankyou />} />
      <Route path="*" element={<NotFound />} />
      
    </Routes>
  );
};

export default Routers;
