import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/car-item.css";

const AssuranceItem = (props) => {
    const { name, image , abrv ,avantages } = props.item;

    return (
      <>
      <Col lg="4" md="4" sm="6" className="mb-5">
        <div className="car__item d-flex flex-column">
          <div className="car__img">
            <img src={image} alt="" className="w-100" />
          </div>
  
          <div className="car__item-content mt-4 flex-grow-1 d-flex flex-column justify-content-between">
            <h4 className="section__title text-center">{name}</h4>
            
  
            <div className="assurance__item-info mt-3 mb-4 flex-grow-1">
              <ul>
                {avantages.map((avantage, index) => (
                  <li key={index} className="avantage-item">
                    <i className="ri-check-line"></i> {avantage}
                  </li>
                ))}
              </ul>
            </div>
  


          </div>
        </div>
        <Col>
      <button className=" w-50 car__item-btn car__btn-rent">
              <Link to={`/Assurances/${abrv}`}>Voir Plus</Link>
            </button>
  
            <button className=" w-50 car__item-btn car__btn-details">
              <Link to={`/devis?assurance=${name}`}>Demander un devis</Link>
            </button>
      </Col>
      </Col>

      </>
  );
};

export default AssuranceItem;
