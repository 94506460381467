// import all images from assets/images directory
import img01 from "../all-images/assurences-img/RCD.png";
import img02 from "../all-images/assurences-img/DO.png";
import img03 from "../all-images/assurences-img/MS.png";
import img04 from "../all-images/assurences-img/RCP.png";
import img05 from "../all-images/assurences-img/MP.png";
import img06 from "../all-images/assurences-img/AT.png";
import img07 from "../all-images/assurences-img/ATD.png";
import img08 from "../all-images/assurences-img/VTC.png";


const AssurancesTypesData = [
    {
      id: 1,
      name: "Responsabilité Civile Décennale",
      abrv: "RCD",
      image : img01 ,
      avantages: [
        "Protection contre les vices cachés",
        "Couverture des dommages matériels et immatériels",
        "Garantie de dix ans après la réception des travaux",
        "Prise en charge des frais de réparation",
        "Assistance juridique en cas de litige",
        "Sérénité pour les maîtres d'ouvrage",
        "Conformité aux obligations légales"
      ]
    },
    {
      id: 2,
      name: "Dommages Ouvrages",
      abrv: "DO",
      image : img02 ,
      avantages: [
        "Remboursement rapide des travaux de réparation",
        "Couverture des dommages dès la réception des travaux",
        "Sécurité financière pour le maître d'ouvrage",
        "Simplification des démarches administratives",
        "Protection contre les malfaçons",
        "Assistance en cas de sinistre",
        "Garanties étendues pour tous les types de constructions"
      ]
    },
    {
      id: 4,
      name: "RC professionnelle",
      abrv: "RCP",
      image : img04 ,
      avantages: [
        "Protection en cas de faute professionnelle",
        "Couverture des dommages corporels, matériels et immatériels",
        "Assistance juridique en cas de litige",
        "Sécurité financière pour l'entreprise",
        "Préservation de la réputation professionnelle",
        "Prise en charge des frais de défense",
        "Garantie de la continuité de l'activité"
      ]
    },
    {
      id: 5,
      name: "Multirisques professionnelle",
      abrv: "MP",
      image : img05 ,
      avantages: [
        "Protection complète des locaux et du matériel",
        "Couverture contre les incendies, vols et dégâts des eaux",
        "Assurance des pertes d'exploitation",
        "Prise en charge des dommages aux biens des tiers",
        "Assistance en cas de sinistre",
        "Garantie des responsabilités civiles",
        "Adaptabilité des garanties selon les besoins spécifiques"
      ]
    },
    {
      id: 6,
      name: "Auto temporaire",
      abrv: "AT",
      image : img06 ,
      avantages: [
        "Couverture pour une durée limitée",
        "Flexibilité des contrats selon la durée souhaitée",
        "Idéal pour les voyages ou les usages ponctuels",
        "Prise en charge des dommages aux tiers",
        "Assistance en cas de panne ou d'accident",
        "Possibilité d'inclure des garanties complémentaires",
        "Rapidité de souscription et de mise en place"
      ]
    },
    {
      id: 7,
      name: "Auto traditionnelle",
      abrv: "ATD",
      image : img07 ,
      avantages: [
        "Protection contre les accidents et les vols",
        "Couverture des dommages matériels et corporels",
        "Assistance en cas de panne",
        "Prise en charge des frais médicaux en cas d'accident",
        "Options de garanties supplémentaires",
        "Réductions pour les conducteurs prudents",
        "Assurance responsabilité civile obligatoire"
      ]
    },
    {
      id : 8,
      name : "VTC / Taxi"  ,
      abrv : "VTC" ,
      image : img08,
      avantages : [
        "Une garantie Responsabilité Civile professionnelle",
        "Protection des effets personnels du chauffeur et de ses clients",
        "La garantie protection du conducteur",
        "Une assistance sans franchise kilométrique",
        "Garanties en cas d'immobilisation"
      ]
    },
    {
      id: 3,
      name: "Mutuelle Santé",
      abrv: "MS",
      image : img03 ,
      avantages: [
        "Remboursement des frais médicaux",
        "Accès à un réseau de soins partenaires",
        "Prise en charge des consultations spécialisées",
        "Couverture des frais dentaires et optiques",
        "Assistance médicale 24/7",
        "Services de prévention et de bien-être",
        "Flexibilité des garanties selon les besoins"
      ]
    }
  ];

  export default AssurancesTypesData;
  