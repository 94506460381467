import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";

const socialLinks = [
    {
        url: "#",
        icon: "ri-facebook-line",
    },
    {
        url: "#",
        icon: "ri-instagram-line",
    },
    {
        url: "#",
        icon: "ri-linkedin-line",
    },
    {
        url: "#",
        icon: "ri-twitter-line",
    },
];
/* 
                                <FormGroup className="contact__form">
                                    <Button color="primary" onClick={() => window.open(nomenclaturePDF, '_blank')}>
                                        Télécharger La Table des activités
                                    </Button>
                                </FormGroup>
*/

const Reclamations = () => {
    return (
        <Helmet title="Reclamations">
            <CommonSection title="Traitement des réclamations" />
            <section>
                <Container>
                    <Row>
                        <Col lg="7" md="7">
                            <div className="about__page-content">
                                <h2 className="section__title">
                                    Traitement des réclamations
                                </h2>

                                <p className="section__description">
                                    Selon la norme ISO 9001:2015, a mis en place une procédure de traitement des réclamations clients particuliers.
                                    <br></br>
                                    Cette procédure est consultable et téléchargeable à l'aide du lien ci-dessous.
                                </p>
                                
                                <p>link</p>

                                <p className="section__description">
                                    Entreprise soumise au contrôle de l'ACPR <a target="_blank" href="http://acpr.banque-france.fr/accueil.html">www.acpr.banque-france.fr</a><br></br>
                                    En cas de différend vous pouvez saisir le Service de réclamation par courrier (Prééminence Assurances
                                    160 ROUTE DE CANNES, 06130 GRASSE) ou par mail (<a href="mailto:contact@preeminence.fr">contact@preeminence.fr</a>) ou par téléphone au numéro suivant (07 45 89 01 61)<br></br>
                                    S'il ne vous est pas donné satisfaction dans le délai de 2 mois, vous pouvez saisir par la suite le :
                                    Médiateur de PLANETE CSCA :<br></br>
                                    à partir du site <a target="_blank" href="http://www.planetecsca.fr">www.planetecsca.fr</a><br></br>
                                    - Par voie électronique à l'adresse suivante: <a href="mailto:contact@planetecsca.fr">contact@planetecsca.fr</a><br></br>
                                    - Par courrier simple adressé à : Médiateur de la consommation de PLANETE CSCA, 10 rue Auber, 75009 Paris.<br></br>
                                    - Par courrier simple adressé à : Médiateur de la consommation de PLANETE CSCA 10 rue Auber 75009 Paris.<br></br>
                                    Ou Autorité de contrôle prudentiel et de résolution (ACPR) : 4 Place de Budapest 75436 PARIS - <a target="_blank" href="http://acpr.banque-france.fr/accueil.html">http://acpr.banque-france.fr/accueil.html</a>.
                                </p>

                                <div className=" d-flex align-items-center gap-3 mt-4">
                                    <span className="fs-4">
                                        <i class="ri-phone-line"></i>
                                    </span>

                                    <div>
                                        <h6 className="section__subtitle">Besoin d'aide?</h6>
                                        <h4>07 45 89 01 61</h4>
                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col lg="5" md="5">
                            <div className="contact__info">
                                <h6 className="fw-bold">Coordonnées</h6>
                                <p className="section__description mb-0">
                                160 ROUTE DE CANNES,
                                06130 GRASSE , FRANCE
                                </p>
                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="fs-6 mb-0">Téléphone:</h6>
                                    <p className="section__description mb-0">07 45 89 01 61</p>
                                </div>

                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="mb-0 fs-6">Email:</h6>
                                    <p className="section__description mb-0">contact@preeminence.fr</p>
                                </div>

                                <h6 className="fw-bold mt-4">Follow Us</h6>

                                <div className="d-flex align-items-center gap-4 mt-3">
                                    {socialLinks.map((item, index) => (
                                        <Link to={item.url} key={index} className="social__link-icon">
                                            <i className={item.icon}></i>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Reclamations;
