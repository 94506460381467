import React from "react";
import "../../styles/our-member.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a1 from "../../assets/partenaires/1.png"
import a2 from "../../assets/partenaires/2.png";
import a3 from "../../assets/partenaires/3.png";
import a4 from "../../assets/partenaires/4.png";
import a5 from "../../assets/partenaires/5.png";
import a6 from "../../assets/partenaires/6.png";
import a7 from "../../assets/partenaires/7.png";
import a8 from "../../assets/partenaires/8.png";
import a9 from "../../assets/partenaires/9.png";
import a10 from "../../assets/partenaires/10.png";
import a11 from "../../assets/partenaires/11.png";
import a12 from "../../assets/partenaires/12.png";
import a13 from "../../assets/partenaires/13.png";
import a14 from "../../assets/partenaires/14.png";
import a15 from "../../assets/partenaires/15.png";
import a16 from "../../assets/partenaires/16.png";
import a17 from "../../assets/partenaires/17.png";


const OUR__MEMBERS = [
  {
    name: "APRIL",
    experience: "L'assurance en plus facile",
    imgUrl: a1,
  },
  {
    name: "HOKEN",
    experience: "Plus qu'un courtier grossiste, votre partenaire !",
    imgUrl: a2,
  },
  {
    name: "AIRBAG",
    experience: "Des solutions d'assurances conçues pour les professionnels",
    imgUrl: a3,
  },
  {
    name: "PROFIRST",
    experience: "Des solutions d'assurance complètes pour les pros",
    imgUrl: a4,
  },
  {
    name: "GROUPR AMI 3F",
    experience: "Le courtier grossiste partenaire de vis succès",
    imgUrl: a5,
  },
  {
    name: "ENTORIA",
    experience: "Distribution exclusive par les courtiers de proximité",
    imgUrl: a6,
  },
  {
    name: "TETRIS",
    experience: "société de courtage en assurances",
    imgUrl: a7,
  },
  {
    name: "SOLLY AZAR",
    experience: "L'assurance différence",
    imgUrl: a8,
  },  {
    name: "IZEHO",
    experience: "Spécialiste des risques professionnels en Iard",
    imgUrl: a9,
  },
  {
    name: "MAAF",
    experience: "A chacun son métier, à chacun sa solution d'assurance professionnelle",
    imgUrl: a10,
  },
  {
    name: "COPPAM",
    experience: "Votre entreprise mérite une assurance et un cout adapté",
    imgUrl: a11,
  },
  {
    name: "GROUPE LEADER Insurance",
    experience: "des solutions d'assurance adaptées allant de la TPE à la grande entreprise",
    imgUrl: a12,
  },
  {
    name: "GROUPE PROWESS",
    experience: "Assurances professionnelles et produits de cautions financières",
    imgUrl: a13,
  },
  {
    name: "BâtiRISK",
    experience: "L'assurance à votre mesure",
    imgUrl: a14,
  },
  {
    name: "AXRE",
    experience: "Agent souscripteur en assurance construction",
    imgUrl: a15,
  },
  {
    name: "NETVOX",
    experience: "Un réseau de courtiers indépendants répartis dans toute la France",
    imgUrl: a16,
  },
  {
    name: "Orus",
    experience: "Un réseau de courtiers indépendants répartis dans toute la France",
    imgUrl: a17,
  }
];

const OurMembers = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    swipeToSlide: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {OUR__MEMBERS.map((item, index) => (
        <Col key={index} className="mb-4 ">
          <div className=" w-372 h-391">
            <div className="single__member-img ">
              <img src={item.imgUrl} alt={item.name} className="w-100" />
              
            </div>

          </div>
        </Col>
      ))}
    </Slider>
  );
};

export default OurMembers;
