import React from "react";
import "../../styles/become-driver.css";
import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

const BecomeClientSection = ({ nom }) => {
  return (
    <section className="become__driver">
      <Container>
        <Row>
          <Col lg="10" md="6" sm="12">
            <h2 className="section__title become__driver-title">
              Besoin de vous assurer ? Demandez votre devis maintenant !
            </h2>
          </Col>
          <Col lg="" md="6" sm="12" className="section__title become__driver-title">
            <button onClick={() => window.location.href = `/devis?assurance=${nom}`} className="btn become__driver-btn mt-4">
              Obtenir Un Devis
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

BecomeClientSection.propTypes = {
  nom: PropTypes.string.isRequired,
};

export default BecomeClientSection;
