import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/logo.png";

const AboutSection2 = ({ aboutClass }) => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="12" md="12" className="d-flex">
            <div className="about__section-content">
              <h4 className="section__subtitle">Qui Sommes-Nous</h4>
              <h2 className="section__title">Bienvenue chez Prééminence Assurances</h2>
              <p className="section__description">
                Né de la volonté de constituer une structure efficace et réactive ayant pour 
                objet d’assurer les intérêts des ses clients, Prééminence Assurances vous 
                accompagne dans vos projets assurantiels. Fort de l’expérience de ses 
                collaborateurs, nous mettons à la disposition de nos clients nos atouts 
                réunissant conseil-stratégie & vision-expertise, ainsi que la proximité 
                tout au long du parcours de votre police d’assurance.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Meilleur Rapport qualité prix
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Toujours Dans le service
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Des Partenariats Forts et fideles
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Application Stricte de la Réglementation
                </p>
              </div>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default AboutSection2;
