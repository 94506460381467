import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import img01 from "../assets/all-images/assurences-img/MS.png" ;


import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const Ms = () => {
  return (
    <Helmet title="Mutuelle Santé">
      <CommonSection title="Assurance Mutuelle Santé" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title">
              Mutuelle Santé
                </h2>

                <h4 className="sous_titre">Remboursement des frais médicaux</h4>
                <p className="section__description">
                La mutuelle santé assure le remboursement des frais médicaux, offrant une aide précieuse pour les consultations, les traitements et les hospitalisations.
                </p>

                <h4 className="sous_titre">Accès à un réseau de soins partenaires</h4>
                <p className="section__description">
                Elle donne accès à un réseau de soins partenaires, permettant de bénéficier de tarifs préférentiels et de services de qualité auprès de nombreux professionnels de santé.
                </p>

                <h4 className="sous_titre">Prise en charge des consultations spécialisées</h4>
                <p className="section__description">
                Les consultations spécialisées, souvent coûteuses, sont prises en charge, facilitant ainsi l'accès aux soins spécifiques et de haut niveau.
                </p>

                <h4 className="sous_titre">Couverture des frais dentaires et optiques</h4>
                <p className="section__description">
                Les frais dentaires et optiques, souvent partiellement remboursés par la sécurité sociale, sont couverts par la mutuelle, réduisant ainsi le reste à charge pour l'assuré.
                </p>

                <h4 className="sous_titre">Assistance médicale 24/7</h4>
                <p className="section__description">
                Une assistance médicale est disponible 24/7, offrant des conseils et une aide rapide en cas de besoin urgent, que ce soit à domicile ou en déplacement.
                </p>

                <h4 className="sous_titre">Services de prévention et de bien-être</h4>
                <p className="section__description">
                Des services de prévention et de bien-être sont inclus, encourageant un mode de vie sain et offrant des programmes de santé adaptés à chaque assuré.
                </p>

                <h4 className="sous_titre">Flexibilité des garanties selon les besoins</h4>
                <p className="section__description">
                Les garanties sont flexibles et peuvent être adaptées en fonction des besoins individuels, permettant de choisir une couverture personnalisée et évolutive.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}

                
              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >

          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Mutuelle Santé"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Ms;
