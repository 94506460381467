import React from "react";
import { Col, Row } from "reactstrap";
import trustpilotLogo from "../../assets/all-images/logo-trustpilot.svg";
import "../../styles/hotOffersSerction.css";

export const HotOffersSerction = () => {
  return (
    <section>
      <Row className="align-items-center">
        <Col lg="12" className="text-center mb-3">
          <div className="rectangle-container">
            <a href="https://fr.trustpilot.com/review/preeminence.fr" target="_blank" rel="noopener noreferrer">
              <img src={trustpilotLogo} alt="Trustpilot" className="trustpilot-logo" />
            </a>
          </div>
        </Col>

          <div className="trustpilot-rating">
            <div className="stars">
              <span className="star filled">★</span>
              <span className="star filled">★</span>
              <span className="star filled">★</span>
              <span className="star filled">★</span>
              <span className="star half-filled">★</span>
            </div>
            <span className="rating-text">4,4</span>
          </div>
          <div className="review-details">
            <span className="review-count">Avis 19</span>
            <span className="rating-separator">•</span>
            <span className="rating-status">Excellent</span>
          </div>

      </Row>
    </section>
  );
};
