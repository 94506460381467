import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";
import img01 from "../assets/all-images/assurences-img/AT.png" ;


import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const At = () => {
  return (
    <Helmet title="Auto temporaire">
      <CommonSection title="Assurance Auto temporaire" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
              
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Assurance Auto Temporaire:
                </h2>

                <h4 className="sous_titre">Couverture pour une durée limitée</h4>
                <p className="section__description">
                  L'assurance auto temporaire offre une protection sur une période définie, idéale pour ceux qui n'ont pas besoin d'une couverture à long terme.
                </p>

                <h4 className="sous_titre">Flexibilité des contrats selon la durée souhaitée</h4>
                <p className="section__description">
                Avec cette option, les assurés peuvent choisir la durée exacte de leur contrat, allant de quelques jours à plusieurs mois, en fonction de leurs besoins spécifiques.
                </p>    

                <h4 className="sous_titre">Idéal pour les voyages ou les usages ponctuels</h4>
                <p className="section__description">
                Parfaite pour les voyages ou les utilisations occasionnelles, l'assurance auto temporaire permet de bénéficier d'une couverture adéquate sans s'engager sur le long terme.
                </p>                
                
                <h4 className="sous_titre">Assistance en cas de panne ou d'accident</h4>
                <p className="section__description">
                En cas de problème sur la route, que ce soit une panne ou un accident, les assurés peuvent compter sur une assistance rapide et efficace.
                </p>                
                
                <h4 className="sous_titre">Possibilité d'inclure des garanties complémentaires</h4>
                <p className="section__description">
                  Les assurés ont la possibilité d'ajouter des garanties supplémentaires, comme la protection juridique ou la couverture contre le vol, pour une protection optimale.
                </p>

                <h4 className="sous_titre">Rapidité de souscription et de mise en place</h4>
                <p className="section__description">
                L'un des grands avantages de l'assurance auto temporaire est la rapidité avec laquelle elle peut être souscrite et mise en place, offrant une solution immédiate et pratique.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >


          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Auto temporaire"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default At;
