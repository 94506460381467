import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";
import img01 from "../assets/all-images/assurences-img/ATD.png" ;


import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const Atd = () => {
  return (
    <Helmet title="Auto traditionnelle">
      <CommonSection title="Assurance Auto traditionnelle" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title">
                  Assurance Auto Traditionnelle
                </h2>

                <h4 className="sous_titre">Protection contre les accidents et les vols</h4>
                <p className="section__description">
                  L'assurance auto traditionnelle offre une protection complète contre les accidents et les vols, garantissant une tranquillité d'esprit à chaque trajet.
                </p>

                <h4 className="sous_titre">Couverture des dommages matériels et corporels</h4>
                <p className="section__description">
                Cette assurance couvre non seulement les dommages matériels causés à votre véhicule, mais aussi les blessures corporelles que vous pourriez subir en cas d'accident.
                </p>    

                <h4 className="sous_titre">Assistance en cas de panne</h4>
                <p className="section__description">
                En cas de panne sur la route, l'assurance auto traditionnelle inclut un service d'assistance pour vous aider rapidement et efficacement, minimisant ainsi les désagréments.                
                </p>                
                
                <h4 className="sous_titre">Prise en charge des frais médicaux en cas d'accident</h4>
                <p className="section__description">
                En cas d'accident, l'assurance prend en charge les frais médicaux, vous permettant de vous concentrer sur votre rétablissement sans souci financier.
                </p>                
                
                <h4 className="sous_titre">Options de garanties supplémentaires</h4>
                <p className="section__description">
                Les assurés peuvent choisir parmi diverses options de garanties supplémentaires, telles que la protection contre le bris de glace ou l'assistance juridique, pour une couverture encore plus complète.
                </p>

                <h4 className="sous_titre">Réductions pour les conducteurs prudents</h4>
                <p className="section__description">
                Des réductions sont souvent offertes aux conducteurs prudents et responsables, récompensant ainsi leur bon comportement sur la route.
                </p>

                <h4 className="sous_titre">Assurance responsabilité civile obligatoire</h4>
                <p className="section__description">
                En respectant la législation, cette assurance inclut la responsabilité civile obligatoire, couvrant les dommages causés à des tiers lors d'un accident.
                </p>

                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >

          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Auto traditionnelle" />

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Atd;
