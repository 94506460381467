import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import img01 from "../assets/all-images/assurences-img/MP.png" ;


import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const Mp = () => {
  return (
    <Helmet title="Multirisques Professionnelle">
      <CommonSection title="Assurance Multirisques Professionnelle" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Assurance Multirisques Professionnelle
                </h2>

                <h4 className="sous_titre">Protection complète des locaux et du matériel</h4>
                <p className="section__description">
                  L'assurance multirisques professionnelle offre une protection complète pour vos locaux et votre matériel, assurant ainsi la continuité de votre activité en cas d'incident.
                </p>

                <h4 className="sous_titre">Couverture contre les incendies, vols et dégâts des eaux</h4>
                <p className="section__description">
                  Elle couvre les sinistres tels que les incendies, les vols et les dégâts des eaux, garantissant une tranquillité d'esprit face aux imprévus.
                </p>

                <h4 className="sous_titre">Assurance des pertes d'exploitation</h4>
                <p className="section__description">
                  En cas d'interruption de l'activité, cette assurance prend en charge les pertes d'exploitation, vous aidant à surmonter les périodes difficiles sans subir de pertes financières majeures.
                </p>

                <h4 className="sous_titre">Prise en charge des dommages aux biens des tiers</h4>
                <p className="section__description">
                  Les dommages causés aux biens des tiers sont également couverts, protégeant ainsi votre entreprise contre les réclamations potentielles de tiers.
                </p>

                <h4 className="sous_titre">Assistance en cas de sinistre</h4>
                <p className="section__description">
                  Une assistance est disponible en cas de sinistre, offrant un soutien rapide et efficace pour gérer les situations d'urgence et minimiser les impacts sur votre activité.
                </p>

                <h4 className="sous_titre">Garantie des responsabilités civiles</h4>
                <p className="section__description">
                  La garantie des responsabilités civiles incluse protège votre entreprise contre les dommages causés à des tiers, que ce soit des clients, des fournisseurs ou des visiteurs.
                </p>

                <h4 className="sous_titre">Adaptabilité des garanties selon les besoins spécifiques</h4>
                <p className="section__description">
                  Les garanties peuvent être adaptées selon les besoins spécifiques de votre entreprise, permettant une couverture sur mesure qui répond parfaitement à vos exigences.
                </p>
                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >


          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="Multirisques Professionnelle"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Mp;
