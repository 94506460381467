import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from "reactstrap";
import axios from "axios";
import Helmet from "../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import CommonSection from "../components/UI/CommonSection";
import "../styles/devis.css";
import nomenclaturePDF from "./nomenclature_activites_BTP-9.pdf";

const socialLinks = [
    { url: "#", icon: "ri-facebook-line" },
    { url: "#", icon: "ri-instagram-line" },
    { url: "#", icon: "ri-linkedin-line" },
    { url: "#", icon: "ri-twitter-line" },
];

const Devis = () => {
    const [hasSiren, setHasSiren] = useState(false);
    const [siren, setSiren] = useState('');
    const [sirenStatus, setSirenStatus] = useState('');
    const [autoFillData, setAutoFillData] = useState({
        name: '',
        companyName: '',
        address: '',
        creationDate: '',
        apeCode: '',
        companyUrl: '',
        dirigeants: [],
    });
    const [formData, setFormData] = useState({
        name: '',
        birthdate: '',
        postalCode: '',
        affiliation: '',
        email: '',
        phone: '',
        registrationNumber: '',
        licenseDate: '',
        bonusMalus: '',
        coverageLevel: '',
        companyName: '',
        address: '',
        creationDate: '',
        apeCode: '',
        employees: '',
        revenue: '',
        primaryActivity: '',
        secondaryActivity: '',
        insured: '',
    });
    const [assurance, setAssurance] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const assuranceValue = queryParams.get('assurance');
        if (assuranceValue) {
            setAssurance(assuranceValue);
        }
    }, [location]);

    const handleSirenChange = async (e) => {
        const sirenValue = e.target.value;
        setSiren(sirenValue);
        setSirenStatus('Recherche en cours...');
        if (sirenValue.length === 9) {
            try {
                const response = await fetch(`https://preemback-693343307929.us-central1.run.app/api/company/${sirenValue}`);
                if (response.ok) {
                    const data = await response.json();
                    const formattedDate = data["Date création entreprise"]
                        ? `${data["Date création entreprise"].slice(0, 2)}-${data["Date création entreprise"].slice(2, 4)}-${data["Date création entreprise"].slice(4, 8)}`
                        : '';
                    const address = data["Adresse postale"];
                    const apeCode = data["Code NAF ou APE"];
                    const companyUrl = data["company url"];
                    const dirigeants = data["dirigeants"];
                    setAutoFillData({
                        name: dirigeants[0] || '',
                        companyName: data.name || '',
                        address: address,
                        creationDate: formattedDate,
                        apeCode: apeCode || '',
                        companyUrl: companyUrl || '',
                        dirigeants: dirigeants || [],
                    });
                    setFormData({ ...formData, name: dirigeants[0]?.name || '' });
                    setSirenStatus('OK');
                } else {
                    setSirenStatus('Aucun résultat trouvé');
                }
            } catch (error) {
                console.error("Error:", error);
                setSirenStatus('Erreur de recherche');
            }
        } else {
            setSirenStatus('');
            setAutoFillData({
                name: '',
                companyName: '',
                address: '',
                creationDate: '',
                apeCode: '',
                companyUrl: '',
                dirigeants: [],
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!acceptTerms) {
            alert("Veuillez accepter les termes et conditions.");
            return;
        }
        setLoading(true); // Commence le chargement
        const payload = {
            siSiren: hasSiren ? siren : '',
            nomComplet: autoFillData.dirigeants[0] || formData.name,
            nomSociete: autoFillData.companyName || formData.companyName,
            adressePostale: autoFillData.address || formData.address,
            dateCreation: autoFillData.creationDate || formData.creationDate,
            codeAPE: autoFillData.apeCode || formData.apeCode,
            effectif: formData.employees,
            chiffreDaffaireHorsTax: formData.revenue,
            activitePrincipal: formData.primaryActivity,
            activiteSecondaire: formData.secondaryActivity,
            siDejaAssure: formData.insured,
            email: formData.email,
            telephone: formData.phone,
            dirigeants: autoFillData.dirigeants,
            urlCompany: autoFillData.companyUrl,
            assurance: assurance,
            coverageLevel: formData.coverageLevel,
            bonusMalus: formData.bonusMalus,
            licenseDate: formData.licenseDate,
            registrationNumber: formData.registrationNumber,
            birthdate: formData.birthdate,
            postalCode: formData.postalCode,
            affiliation: formData.affiliation,
        };
        try {
            await axios.post('https://preemback-693343307929.us-central1.run.app/api/send_email/devis', payload);
            navigate('/thankyou');
        } catch (error) {
            console.error('Erreur lors de l\'envoi du formulaire:', error);
        } finally {
            setLoading(false); // Arrête le chargement même en cas d'erreur
        }
    };

    const renderFormFields = () => {
        switch (assurance) {
            case 'Mutuelle Santé':
                return (
                    <>
                        <FormGroup className="contact__form">
                            <Label for="name">Nom et Prénom</Label>
                            <Input id="name" name="name" placeholder="Nom et Prénom" type="text" value={formData.name} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="birthdate">Date de Naissance</Label>
                            <Input id="birthdate" name="birthdate" placeholder="Date de Naissance" type="date" value={formData.birthdate} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="postalCode">Code Postal</Label>
                            <Input id="postalCode" name="postalCode" placeholder="Code Postal" type="text" value={formData.postalCode} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="affiliation">Régime d'affiliation</Label>
                            <Input id="affiliation" name="affiliation" type="select" value={formData.affiliation} onChange={handleChange} required>
                                <option value="">Sélectionnez une option</option>
                                <option value="Sécurité sociale">Sécurité sociale</option>
                                <option value="TNS">TNS</option>
                                <option value="MSA">MSA</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="email">Adresse mail</Label>
                            <Input id="email" name="email" placeholder="Adresse mail" type="email" value={formData.email} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="phone">Numéro de téléphone</Label>
                            <Input id="phone" name="phone" placeholder="Numéro de téléphone" type="tel" value={formData.phone} onChange={handleChange} required />
                        </FormGroup>
                    </>
                );
            case 'Auto temporaire': return (
                <>
                    <FormGroup className="contact__form">
                        <Label for="name">Nom et Prénom</Label>
                        <Input id="name" name="name" placeholder="Nom et Prénom" type="text" value={formData.name} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="birthdate">Date de Naissance</Label>
                        <Input id="birthdate" name="birthdate" placeholder="Date de Naissance" type="date" value={formData.birthdate} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="registrationNumber">Numéro d'immatriculation</Label>
                        <Input id="registrationNumber" name="registrationNumber" placeholder="Numéro d'immatriculation" type="text" value={formData.registrationNumber} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="licenseDate">Date d'obtention du permis</Label>
                        <Input id="licenseDate" name="licenseDate" placeholder="Date d'obtention du permis" type="date" value={formData

                            .licenseDate} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="bonusMalus">BONUS / MALUS</Label>
                        <Input id="bonusMalus" name="bonusMalus" placeholder="BONUS / MALUS" type="text" value={formData.bonusMalus} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="email">Adresse mail</Label>
                        <Input id="email" name="email" placeholder="Adresse mail" type="email" value={formData.email} onChange={handleChange} required />
                    </FormGroup>
                    <FormGroup className="contact__form">
                        <Label for="phone">Numéro de téléphone</Label>
                        <Input id="phone" name="phone" placeholder="Numéro de téléphone" type="tel" value={formData.phone} onChange={handleChange} required />
                    </FormGroup>
                </>
            );
            case 'Auto traditionnelle':
                return (
                    <>
                        <FormGroup className="contact__form">
                            <Label for="name">Nom et Prénom</Label>
                            <Input id="name" name="name" placeholder="Nom et Prénom" type="text" value={formData.name} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="birthdate">Date de Naissance</Label>
                            <Input id="birthdate" name="birthdate" placeholder="Date de Naissance" type="date" value={formData.birthdate} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="registrationNumber">Numéro d'immatriculation</Label>
                            <Input id="registrationNumber" name="registrationNumber" placeholder="Numéro d'immatriculation" type="text" value={formData.registrationNumber} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="licenseDate">Date d'obtention du permis</Label>
                            <Input id="licenseDate" name="licenseDate" placeholder="Date d'obtention du permis" type="date" value={formData

                                .licenseDate} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="bonusMalus">BONUS / MALUS</Label>
                            <Input id="bonusMalus" name="bonusMalus" placeholder="BONUS / MALUS" type="text" value={formData.bonusMalus} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="coverageLevel">Niveau de couverture</Label>
                            <Input id="coverageLevel" name="coverageLevel" type="select" value={formData.coverageLevel} onChange={handleChange} required>
                                <option value="">Sélectionnez une option</option>
                                <option value="Tiers">Tiers</option>
                                <option value="Tiers et Vol et Incendie Et BDG">Tiers et Vol et Incendie Et BDG</option>
                                <option value="Tous Risques">Tous Risques</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="email">Adresse mail</Label>
                            <Input id="email" name="email" placeholder="Adresse mail" type="email" value={formData.email} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="phone">Numéro de téléphone</Label>
                            <Input id="phone" name="phone" placeholder="Numéro de téléphone" type="tel" value={formData.phone} onChange={handleChange} required />
                        </FormGroup>
                    </>
                );
            case 'VTC / Taxi':
                return (
                    <>
                        <FormGroup className="contact__form">
                            <Label for="sirenQuestion">Avez-vous un numéro de SIREN ?</Label>
                            <div className="d-flex" style={{ gap: '10px' }}>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="sirenQuestion"
                                            value="yes"
                                            onChange={() => setHasSiren(true)}
                                        />{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="sirenQuestion"
                                            value="no"
                                            onChange={() => setHasSiren(false)}
                                        />{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>

                        {hasSiren && (
                            <>
                                <FormGroup className="contact__form">
                                    <Label for="siren">Numéro de SIREN</Label>
                                    <Input
                                        id="siren"
                                        placeholder="Entrez le numéro de SIREN"
                                        type="text"
                                        value={siren}
                                        onChange={handleSirenChange}
                                    />
                                    <p>{sirenStatus}</p>
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="name">Nom Prénom</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Nom Prénom"
                                        type="text"
                                        value={autoFillData.dirigeants[0]}

                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="companyName">Nom de l'entreprise</Label>
                                    <Input
                                        id="companyName"
                                        placeholder="Nom de l'entreprise"
                                        type="text"
                                        value={autoFillData.companyName}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="address">Adresse Postale</Label>
                                    <Input
                                        id="address"
                                        placeholder="Adresse Postale"
                                        type="text"
                                        value={autoFillData.address}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="creationDate">Date de Création</Label>
                                    <Input
                                        id="creationDate"
                                        placeholder="Date de Création"
                                        type="text"
                                        value={autoFillData.creationDate}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="apeCode">Code APE</Label>
                                    <Input
                                        id="apeCode"
                                        placeholder="Code APE"
                                        type="text"
                                        value={autoFillData.apeCode}
                                        pattern="\d{4}[a-zA-Z]?"
                                        readOnly
                                    />
                                </FormGroup>
                                <Input
                                    id="companyUrl"
                                    type="hidden"
                                    value={autoFillData.companyUrl}
                                    readOnly
                                />
                                <Input
                                    id="dirigeants"
                                    type="hidden"
                                    value={autoFillData.dirigeants.join(', ')}
                                    readOnly
                                />
                            </>
                        )}

                        {!hasSiren && (
                            <>
                                <FormGroup className="contact__form">
                                    <Label for="name">Nom Prénom</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Nom Prénom"
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="companyName">Nom de l'entreprise</Label>
                                    <Input
                                        id="companyName"
                                        name="companyName"
                                        placeholder="Nom de l'entreprise"
                                        type="text"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="address">Adresse Postale</Label>
                                    <Input
                                        id="address"
                                        name="address"
                                        placeholder="Adresse Postale"
                                        type="text"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="creationDate">Date de Création</Label>
                                    <Input
                                        id="creationDate"
                                        name="creationDate"
                                        placeholder="Date de Création"
                                        type="text"
                                        value={formData.creationDate}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="apeCode">Code APE</Label>
                                    <Input
                                        id="apeCode"
                                        name="apeCode"
                                        placeholder="Code APE"
                                        type="text"
                                        value={formData.apeCode}
                                        onChange={handleChange}
                                        pattern="\d{4}[a-zA-Z]?"
                                    />
                                </FormGroup>
                            </>
                        )}
                        <FormGroup className="contact__form">
                            <Label for="birthdate">Date de Naissance</Label>
                            <Input id="birthdate" name="birthdate" placeholder="Date de Naissance" type="date" value={formData.birthdate} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="registrationNumber">Numéro d'immatriculation</Label>
                            <Input id="registrationNumber" name="registrationNumber" placeholder="Numéro d'immatriculation" type="text" value={formData.registrationNumber} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="licenseDate">Date d'obtention du permis</Label>
                            <Input id="licenseDate" name="licenseDate" placeholder="Date d'obtention du permis" type="date" value={formData.licenseDate} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="bonusMalus">BONUS / MALUS</Label>
                            <Input id="bonusMalus" name="bonusMalus" placeholder="BONUS / MALUS" type="text" value={formData.bonusMalus} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="coverageLevel">Niveau de couverture</Label>
                            <Input id="coverageLevel" name="coverageLevel" type="select" value={formData.coverageLevel} onChange={handleChange} required>
                                <option value="">Sélectionnez une option</option>
                                <option value="Tiers">Tiers</option>
                                <option value="Tiers et Vol et Incendie Et BDG">Tiers et Vol et Incendie Et BDG</option>
                                <option value="Tous Risques">Tous Risques</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="email">Adresse mail</Label>
                            <Input id="email" name="email" placeholder="Adresse mail" type="email" value={formData.email} onChange={handleChange} required />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="phone">Numéro de téléphone</Label>
                            <Input id="phone" name="phone" placeholder="Numéro de téléphone" type="tel" value={formData.phone} onChange={handleChange} required />
                        </FormGroup>


                    </>
                );
            default:
                return (
                    <>
                        <FormGroup className="contact__form">
                            <Label for="sirenQuestion">Avez-vous un numéro de SIREN ?</Label>
                            <div className="d-flex" style={{ gap: '10px' }}>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="sirenQuestion"
                                            value="yes"
                                            onChange={() => setHasSiren(true)}
                                        />{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="sirenQuestion"
                                            value="no"
                                            onChange={() => setHasSiren(false)}
                                        />{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>

                        {hasSiren && (
                            <>
                                <FormGroup className="contact__form">
                                    <Label for="siren">Numéro de SIREN</Label>
                                    <Input
                                        id="siren"
                                        placeholder="Entrez le numéro de SIREN"
                                        type="text"
                                        value={siren}
                                        onChange={handleSirenChange}
                                    />
                                    <p>{sirenStatus}</p>
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="name">Nom Prénom</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Nom Prénom"
                                        type="text"
                                        value={autoFillData.dirigeants[0]}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="companyName">Nom de l'entreprise</Label>
                                    <Input
                                        id="companyName"
                                        placeholder="Nom de l'entreprise"
                                        type="text"
                                        value={autoFillData.companyName}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="address">Adresse Postale</Label>
                                    <Input
                                        id="address"
                                        placeholder="Adresse Postale"
                                        type="text"
                                        value={autoFillData.address}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="creationDate">Date de Création</Label>
                                    <Input
                                        id="creationDate"
                                        placeholder="Date de Création"
                                        type="text"
                                        value={autoFillData.creationDate}
                                        readOnly
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="apeCode">Code APE</Label>
                                    <Input
                                        id="apeCode"
                                        placeholder="Code APE"
                                        type="text"
                                        value={autoFillData.apeCode}
                                        pattern="\d{4}[a-zA-Z]?"
                                        readOnly
                                    />
                                </FormGroup>
                                <Input
                                    id="companyUrl"
                                    type="hidden"
                                    value={autoFillData.companyUrl}
                                    readOnly
                                />
                                <Input
                                    id="dirigeants"
                                    type="hidden"
                                    value={autoFillData.dirigeants.join(', ')}
                                    readOnly
                                />
                            </>
                        )}

                        {!hasSiren && (
                            <>
                                <FormGroup className="contact__form">
                                    <Label for="name">Nom Prénom</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        placeholder="Nom Prénom"
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="companyName">Nom de l'entreprise</Label>
                                    <Input
                                        id="companyName"
                                        name="companyName"
                                        placeholder="Nom de l'entreprise"
                                        type="text"
                                        value={formData.companyName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="address">Adresse Postale</Label>
                                    <Input
                                        id="address"
                                        name="address"
                                        placeholder="Adresse Postale"
                                        type="text"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="creationDate">Date de Création</Label>
                                    <Input
                                        id="creationDate"
                                        name="creationDate"
                                        placeholder="Date de Création"
                                        type="text"
                                        value={formData.creationDate}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="contact__form">
                                    <Label for="apeCode">Code APE</Label>
                                    <Input
                                        id="apeCode"
                                        name="apeCode"
                                        placeholder="Code APE"
                                        type="text"
                                        value={formData.apeCode}
                                        onChange={handleChange}
                                        pattern="\d{4}[a-zA-Z]?"
                                    />
                                </FormGroup>
                            </>
                        )}

                        <FormGroup className="contact__form">
                            <Label for="employees">Effectif gérant compris</Label>
                            <Input
                                id="employees"
                                name="employees"
                                placeholder="Effectif gérant compris"
                                type="number"
                                value={formData.employees}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="revenue">Chiffre d’affaires hors taxe</Label>
                            <Input
                                id="revenue"
                                name="revenue"
                                placeholder="Chiffre d’affaires hors taxe"
                                type="number"
                                value={formData.revenue}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="primaryActivity">Activité principale</Label>
                            <Input
                                id="primaryActivity"
                                name="primaryActivity"
                                placeholder="Activité principale"
                                type="text"
                                value={formData.primaryActivity}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Button color="primary" onClick={() => window.open(nomenclaturePDF, '_blank')}>
                                Télécharger La Table des activités
                            </Button>
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="secondaryActivity">Activité secondaire</Label>
                            <Input
                                id="secondaryActivity"
                                name="secondaryActivity"
                                placeholder="Activité secondaire"
                                type="text"
                                value={formData.secondaryActivity}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="insuredQuestion">Avez-vous déjà été assuré ?</Label>
                            <div className="d-flex" style={{ gap: '10px' }}>
                                <FormGroup check className="mr-2">
                                    <Label check>
                                        <Input type="radio" name="insured" value="Oui" onChange={handleChange} required />{' '}
                                        Oui
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="insured" value="Non" onChange={handleChange} required />{' '}
                                        Non
                                    </Label>
                                </FormGroup>
                            </div>
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="email">Adresse mail</Label>
                            <Input
                                id="email"
                                name="email"
                                placeholder="Adresse mail"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup className="contact__form">
                            <Label for="phone">Numéro de téléphone</Label>
                            <Input
                                id="phone"
                                name="phone"
                                placeholder="Numéro de téléphone"
                                type="tel"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>

                    </>
                );
        }
    };

    return (
        <Helmet title="Devis">
            <CommonSection title={`Devis pour ${assurance}`} />
            <section>
                <Container>
                    <Row>
                        <Col lg="7" md="7">
                            <h6 className="fw-bold mb-4">Remplir le formulaire pour Obtenir Un Devis</h6>
                            <Form onSubmit={handleSubmit}>
                                {renderFormFields()}
                                <FormGroup check className="contact__form">
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={acceptTerms}
                                            onChange={() => setAcceptTerms(!acceptTerms)}
                                            required
                                        />{' '}
                                        En cliquant sur J'OBTIENS MON TARIF, j’accepte qu’un expert me contacte...
                                    </Label>
                                </FormGroup>
                                <button className="contact__btn" type="submit" disabled={loading}>
                                    {loading ? 'Envoi en cours...' : 'J\'OBTIENS MON TARIF'}
                                </button>
                            </Form>
                        </Col>
                        <Col lg="5" md="5">
                            <div className="contact__info">
                                <h6 className="fw-bold">Coordonnées</h6>
                                <p className="section__description mb-0">
                                    160 ROUTE DE CANNES,
                                    06130 GRASSE, FRANCE
                                </p>
                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="fs-6 mb-0">Téléphone:</h6>
                                    <p className="section__description mb-0">07 45 89 01 61</p>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    <h6 className="mb-0 fs-6">Email:</h6>
                                    <p className="section__description mb-0">contact@preeminence.fr</p>
                                </div>
                                <h6 className="fw-bold mt-4">Follow Us</h6>
                                <div className="d-flex align-items-center gap-4 mt-3">
                                    {socialLinks.map((item, index) => (
                                        <Link to={item.url} key={index} className="social__link-icon">
                                            <i className={item.icon}></i>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Devis;
