import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";

import driveImg from "../assets/all-images/drive.jpg";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";
import img01 from "../assets/all-images/assurences-img/VTC.png" ;


import "../styles/about-section.css";
import aboutImg from "../assets/all-images/logo.png";

const VtcTaxi = () => {
  return (
    <Helmet title="Vtc / Taxi">
      <CommonSection title="Assurance Vtc / Taxi" />
      <section className="about__page-section">
        <Container>

          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={img01} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
              <h2 className="section__title">
                  Assurance VTC / Taxi
                </h2>

                <h4 className="sous_titre">Une garantie Responsabilité Civile professionnelle</h4>
                <p className="section__description">
                L'assurance VTC / Taxi inclut une garantie responsabilité civile professionnelle, couvrant les dommages causés à des tiers pendant l'activité de transport, assurant ainsi une protection complète en cas d'accident ou d'incident.
                </p>

                <h4 className="sous_titre">Protection des effets personnels du chauffeur et de ses clients</h4>
                <p className="section__description">
                Cette assurance offre une protection pour les effets personnels du chauffeur et de ses clients, garantissant une indemnisation en cas de vol ou de dommage des biens transportés.
                </p>

                <h4 className="sous_titre">La garantie protection du conducteur</h4>
                <p className="section__description">
                La garantie protection du conducteur couvre les blessures et les dommages subis par le chauffeur en cas d'accident, assurant ainsi une sécurité financière et médicale en toutes circonstances.
                </p>

                <h4 className="sous_titre">Une assistance sans franchise kilométrique</h4>
                <p className="section__description">
                En cas de panne ou de problème sur la route, l'assurance propose une assistance sans franchise kilométrique, offrant un soutien immédiat où que vous soyez, sans restriction de distance.
                </p>

                <h4 className="sous_titre">Garanties en cas d'immobilisation</h4>
                <p className="section__description">
                Si le véhicule est immobilisé, l'assurance VTC / Taxi inclut des garanties pour minimiser les impacts sur l'activité, comme la prise en charge des frais de dépannage et des solutions de remplacement temporaire du véhicule.
                </p>

                {/* 

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+00123456789</h4>
                  </div>
                </div>
                */}


              </div>
            </Col>
          </Row>

          {/* **************************  */}
          <section
            className="about__section"
            style={{ marginTop: "0px" }}
          >


          </section>
          {/* **************************  */}

        </Container>
      </section >

      <BecomeClientSection nom="VTC / Taxi"/>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">Découvrir</h6>
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default VtcTaxi;
