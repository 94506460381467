import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection2 from "../components/UI/AboutSection2";
import { Container, Row, Col } from "reactstrap";
import BecomeClientSection from "../components/UI/BecomeClientSection";

import driveImg from "../assets/all-images/solutions.png";
import OurMembers from "../components/UI/OurMembers";
import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="À propos de nous" />
      
      <AboutSection2 aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  Vous avez un projet ? Nous avons la solution !
                </h2>

                <p className="section__description">
                  Preeminence assurances est en mesure de répondre aux professionnels et aux particuliers.<br></br>
                  Nos experts s’engagent à vous trouver une solution quel que soit votre profil.<br></br>
                  Nos différents partenaires nous permettent d’assurer les cas difficiles, le mot « impossible n’existe pas ».
                  N’hésitez pas à nous confier vos projets d’assurances tous segments confondus, le résultat sera à la hauteur de vos attentes.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <br></br>
                    <i class="ri-phone-line"></i>
                    <br></br>
                    <i class="ri-mail-send-line"></i>  
                  </span>

                  <div>
                    <h6 className="section__subtitle">Besoin d'informations ?</h6>
                    <h4>07 57 93 37 97</h4>
                    <h4>contact@preeminence.fr</h4>
                  </div>

                  
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      

      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h2 className="section__title">Nos Partenaires</h2>
            </Col>
            <OurMembers />
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
