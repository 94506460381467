import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import CarteMaps from "../UI/CarteMaps";

const quickLinks = [
  {
    path: "/about",
    display: "Qui Sommes Nous ?",
  },



  {
    path: "/mentionslegales",
    display: "Mentions Légales",
  },
  {
    path: "/reclamations",
    display: "Traitement des réclamations",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i class="ri-vip-diamond-fill"></i>
                  <span>
                    Prééminence <br /> Assurances
                  </span>
                </Link>
              </h1>
            </div>
            <div className="mt-3">
            <p className="office__info">160 ROUTE DE CANNES,
            06130 GRASSE</p>
              <p className="office__info">Téléphone: 07 45 89 01 61</p>

              <p className="office__info">Email: 	contact@preeminence.fr</p>

              <p className="office__info">Du lundi au vendredi
                9:00 - 19:00</p>
                <p className="office__info">Samedi
                10:00 - 13:00</p>
            </div>

          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Liens Rapides</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          

          <Col lg="4" md="4" sm="12">
            <CarteMaps/>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
              Prééminence Assurances<i class="ri-copyright-line"></i>Copyright {year}, Developed by
                <a href="https://webbin.services" target="_blank">Webbin</a> . All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
